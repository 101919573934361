.create-need-for-action-form {
    border-bottom: 1px solid var(--neutrals-200);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
}
.create-need-for-action-form-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}
.update-need-for-action-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}
.update-need-for-action-comments-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}
.update-need-for-action-comments-wrapper .create-measure-detail-enter-hint {
    justify-self: flex-end;
    margin: 0;
}
.update-need-for-action-comments {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
}
.update-need-for-action-comments-icon-created-at {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
}
.create-need-for-action-comments-border {
    border-left: 1px solid var(--neutrals-900);
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-left: 20px;
}
.create-need-for-action-comment {
    display: flex;
    align-items: center;
    gap: 16px;
}
.create-need-for-action-comment-icon {
    display: flex;
    gap: 9px;
    align-items: center;
    color: var(--neutrals-400);
}
.create-need-for-action-comment-icon svg path {
    fill: var(--neutrals-400);
}
.single-objective-dropdown {
    margin-left: auto;
}
.single-objective-dropdown .dropdown-items {
    width: 224px;
    right: 0;
}

.single-objective-dropdown-body {
    right: 0;
}
