.presence-calendar-attendance-menu {
    display: flex;
    padding: 10px;
    box-shadow: var(--shadow-base);
    background-color: #fff;
    position: absolute;
    transform: translateX(-50%);
    z-index: 100;
}
.presence-calendar-attendances {
    display: flex;
    gap: 12px;
    position: relative;
}
.presence-calendar-attendance-menu-close {
    cursor: pointer;
}
.presence-calendar-attendance-menu-close:hover svg path {
    width: 2px;
}
.arrow-up {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    transform: translateX(-50%);
    z-index: 10;
}
