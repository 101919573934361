.side-bar-footer {
    background-color: #212124;
    padding: 15px 28px 15px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--neutrals-700);
    color: var(--neutrals-200);
    margin-top: auto;
}

.side-bar-footer-avatar-name {
    display: flex;
    gap: 8px;
    align-items: center;
}

.side-bar-footer svg path {
    fill: var(--neutrals-200);
}

.side-bar-footer-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
}

.side-bar-footer-logout {
    display: flex;
    align-items: center;
    color: var(--neutrals-500);
    cursor: pointer;
}
.side-bar-footer-logout svg path {
    fill: var(--neutrals-500);
}

.side-bar-footer-logout:hover {
    text-decoration: underline;
}
