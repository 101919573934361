.create-need-for-action {
    width: 100%;
    height: 100%
}
.create-need-for-action-head {
    display: flex;
    gap: 18px;
    align-items: center;
}
.create-need-for-action-head svg path {
    fill: var(--primary-500);
}
.tag-need-for-action {
    color: #fff;
    display: flex;
    align-items: center;
}
.tag-qualifikation {
    background-color: #662DDE
}
.tag-alltagsundsozialekompetenzen {
    background-color: #3307B6;
}
.tag-angehrigesozialesnetzwerk {
    background: #A90CC2;
}
.tag-arbeitsundsozialverhalten {
    background-color: #05AEE3;
}
.tag-finanziellesituation {
    background-color: #117600;
}
.tag-gesundheit {
    background-color: #73A239;
}
.tag-strafflligkeit {
    background-color: #73005A;
}
.tag-beruflicheorientierungbo {
    background-color: #120082
}
.tag-aufenthaltsrechtlichesituation {
    background-color: #3375D9;
}
.tag-statusbeiaustrittundverbleib {
    background-color: #1026E9;
}
.tag-wohnen {
    background-color: #E42C8F;
}
