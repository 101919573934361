.indicator {
display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    background-color: var(--error-500);
    border-radius: 4px;
    color: #fff;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
}
