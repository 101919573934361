.participantNameContainer {
    position: relative;
    z-index: 101;
    display: flex;
    justify-content: center;
    width: 100%;
}

.participantNameContainer > h6 {
    position: fixed;
    top: 0;
    background-color: white;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    box-shadow: var(--shadow-base);
    padding: 0.5rem 1rem;
    transform: translateX(-50%)
}