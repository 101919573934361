.object-list-item {
    padding: 10px 8px;
    border-radius: 6px;
    cursor: default;
}
.object-list-item:hover {
    background-color: var(--neutrals-100);
}
.object-list-item.active {
    background-color: var(--primary-50);
    color: var(--primary-600);
}
