.company-details-head {
    display: flex;
    justify-content: space-between;
}
 textarea {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--neutrals-900);
     padding: 16px;
     border-radius: 6px;
     box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);

 }
textarea:focus {
    outline: none !important;
    border:1px solid var(--primary-500);
    border-radius: 6px;
}
