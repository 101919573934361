.container {
    display: flex;
    justify-content: space-between;
}

.container > div {
    display: flex;
    gap: 8px;
    align-items: center;
}

.description {
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}