.form-text-area {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    gap: 10px;
}
.form-text-area-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 10px;
}
.form-text-area textarea {
    border: 1px solid var(--neutrals-300);
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    min-height: 160px;
    resize: vertical;
}
.form-text-area textarea:hover {
    border-color: var(--neutrals-400);
}
.form-text-area textarea:focus-visible {
    border: 1px solid var(--primary-500);
    outline: none;
}
.form-text-area textarea.value:not(:focus) {
    border-color: var(--neutrals-300);
}
.form-text-area textarea.small {
    height: 32px;
    padding: 8px;
}
.form-text-area textarea.medium {
    height: 40px;
    padding: 8px 16px;
}
.form-text-area textarea.large {
    height: 48px;
    padding: 8px 16px;
}
.form-text-area a {
    color: var(--primary-500);
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
}
.form-text-area .textarea-helper-error-container {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding-top: 8px;
}
.form-text-area .helper {
    color: var(--neutrals-500);
}
.form-text-area .error {
    border-color: var(--error-500);
    color: var(--error-500);
}
