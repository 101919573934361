.delete-button-modal {
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: var(--shadow-md);
    padding: 16px;
    position: absolute;
    min-width: 199px;
    top: 105%;
    right: 50%;
    gap: 8px;
    transform: translateX(50%);
    display: flex;
    flex-direction: column;
    z-index: 2;
}
.delete-button-modal-label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    color: #27272A;
}
.delete-button-width {
    width: 100%;
    cursor: pointer;
}
.delete-button-width > div {
    width: 100%;
    cursor: pointer;
}
