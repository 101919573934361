.avatar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.avatar-container {
    border-radius: 32px;
    overflow: hidden;
}
.avatar-container.small {
    width: 26px;
    height: 26px;
}
.avatar-container.medium {
    width: 28px;
    height: 28px;
}
.avatar-container.large {
    width: 32px;
    height: 32px;
}
.avatar-container-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.avatar-description {
    color: var(--neutrals-400);
}
