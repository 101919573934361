.participant-notes-wrapper-button {
    position: fixed;
    top: 50%;
    right: -125px;
    transition: all 1s;
    cursor: pointer;
    z-index: 200;
    min-width: 165px;
}
.participant-notes-wrapper-button > div {
    background-color: white;
    cursor: pointer;
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    gap: 13px;
    border-right: none !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.participant-notes-wrapper-button:hover {
    right: 0;
}
