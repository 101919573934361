.created-user-password-modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.created-user-password-modal-password {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.created-user-password-modal-password-copied {
    display: flex;
    justify-content: center;
}
