.company-internships {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.company-internships-head {
    display: flex;
}
.company-internships-head > div {
    flex: 1
}
.company-internships-head div:last-child {
    flex: 0.25
}
.company-internships-body {
    display: flex;
    gap: 13px;
    flex-direction: column;
    position: relative;
}
.company-internships-body-item{
    flex: 1;
    color: var(--neutrals-500);
    display: flex;
    cursor: default;
}
.delete-and-update {
    opacity: 0;
    display: flex;
}
.company-internships-body-item:hover .delete-and-update{
    opacity: 1;
}
.company-internships-body-item-element {
    flex: 1;
}
.company-internships-body-item-element:nth-child(3) {

    white-space: nowrap;
}
.company-internships-body-item-element:last-child {
   flex: 0.25;
}
.delete-internship {
    position: absolute;
    right: 0;
    text-decoration: underline;
    display: none;
    cursor: pointer;
}
.company-internships-body-item-element-update:hover svg path {
    cursor: pointer;
    fill: black;
}
.company-internships-body-item-element-update:hover svg {
    cursor: pointer;
}
.company-internships-body-item-element-delete:hover svg {
    cursor: pointer;
}
.company-internships-body-item-element-delete:hover svg path {
    cursor: pointer;
    fill: var(--error-500);
}
