.create-measure {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    z-index: 100;
}

.create-measure-detail {
    display: flex;
    flex-direction: column;
    padding: 175px 222px;
    background-color: var(--neutrals-50);
    flex: 1;
    height: 100%;
    overflow: scroll;
    box-sizing: border-box;
}

.create-measure-menu {
    flex: 0.3
}
.create-measure-detail-sub-header {
    color: var(--primary-600);
    margin-bottom: 16px;
}
.create-measure-detail-enter-hint {
    color: var(--neutrals-500);
    display: flex;
    align-items: center;
    align-self: flex-end;
    gap: 6px;
    margin-top: 20px
}
.create-measure-detail-enter-hint-icon {

}
.create-measure-detail-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}
.create-measure-submit-error {
    background-color: var(--error-500);
    color: white;
    margin-top: 1rem;
    padding: 10px;
    border-radius: 8px;
}
.create-measure-street-zip {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
