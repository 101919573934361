.qm-document-template-details-head {
    display: flex;
    justify-content: space-between;
}
.qm-document-template-details-head-edit-buttons {
    display: flex;
    gap: 12px;
}
.color-red svg path {
    fill: var(--error-500) !important;
}
