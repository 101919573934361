.participants-management-table {
    display: grid;
    grid-template-columns: 50% 15% 15% 15% 5%;
    color: var(--neutrals-500);
    padding: 2px 8px;
    cursor: default;
    grid-row-gap: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    align-items: center;
    width: 100%;
}

.participants-management-table > .icons-container {
    display: flex;
    flex-direction: row;
}

.participants-management-table.entry:hover {
    background-color: var(--neutrals-100);
}

.participants-management-table-delete {
    box-sizing: border-box;
    opacity: 0;
}
.participants-management-table-delete svg {
    cursor: pointer;
}
.participants-management-table.entry:hover .participants-management-table-delete {
    display: flex;
    justify-content: flex-end;
    opacity: 1;
    color: var(--error-500);
}

.participants-management-table.entry:hover .participants-management-table-delete svg path {
    fill: var(--error-500);
}

.participant-checkbox-label {
    display: flex;
}
