a {
  color: black;
  text-decoration: none;
}
a:visited {
  color: black;
  text-decoration: none;
}
:root {
  --shadow-base:  0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

@font-face {
  font-family: "Inter";
  src: url('./fonts/Inter.ttf');
}
.d-flex {
  display: flex;
}
.gap-1 {
  gap: 1rem;
}
.align-self-end {
  align-self: flex-end;
  margin-left: auto;
}
.ml-auto {
  margin-left: auto;
}
.width-100 {
  width: 100%;
}
.width-auto {
  width: auto !important;
}
.flex-1 {
  flex: 1;
}
.height-auto {
  height: auto !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
}

.dropdown-list {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  border: 1.5px solid transparent;
}
.dropdown-list-title {
  padding: 0 8px 0 !important;
}
.dropdown-form-title {
  padding: 0 !important;
}
.truncate {
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.cursor-pointer {
  cursor: pointer;
}

