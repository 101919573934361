.statistics-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.statistics-print-button {
    align-self: flex-end;
}
.statistics {
    display: flex;
    flex-wrap: wrap;
    gap: 56px;
    overflow: auto;
}
.statistics-accordions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
}
.statistics-details {
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 22px;
}
.statistics-details-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
}
.statistics-details.bottom {
    border-bottom: 1px solid var(--neutrals-200);
}
.statistics-item {
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
    text-align: end;
}
.statistics-details-list {
    display: flex;
    flex-direction: column;
    gap: 13px;
}
