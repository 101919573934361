.need-for-action-card {
    cursor: pointer;
    height: auto;
}

.need-for-action-card-head {
    display: flex;
    justify-content: space-between;
}

.need-for-action-card-tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.tag-support-plan {
    background-color: #909090;
}

.need-for-action-card-upper-part {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
}
.need-for-action-card-options {
    display: flex;
    overflow: hidden;
}
.need-for-action-card-options > div {
  white-space: nowrap;
}
.need-for-action-card-upper-part.border-bottom {
    border-bottom: 1px solid var(--neutrals-200);
}

.need-for-action-card-comments {
    padding: 10px 0 10px 0;
}

.need-for-action-card-comments-single-comment {
    display: flex;
    gap: 8px;
}

.need-for-action-card-low {
    background-color: var(--success-100);
    color: var(--success-900);
}
.need-for-action-card-information {
    background-color: var(--success-100);
    color: var(--success-900);
}
.need-for-action-card-closed {
    background-color: var(--success-100);
    color: var(--success-900);
}
.need-for-action-card-medium {
    background-color: var(--warning-50);
    color: var(--warning-900);
}

.need-for-action-card-high {
    background-color: var(--error-100);
    color: var(--error-900);
}
