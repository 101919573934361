.container {
    display: flex;
    justify-content: space-between;
}
.name {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0.025em;
    color: var(--neutrals-400);
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.date {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutrals-400);
}
