.object-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 296px;
    box-sizing: border-box;
    height: 100%;
    border-right: 1px solid var(--neutrals-200);
}
.object-list-item-note {

}
