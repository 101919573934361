.sub-nav-tab {
    display: flex;
    gap: 24px;
    color: var(--neutrals-500);
    margin-top: 24px;
}
.sub-nav-tab-item {
    padding-bottom: 16px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.sub-nav-tab-item.active {
    color: var(--primary-600);
    border-bottom: 2px solid var(--primary-600);
}
