.measure-information-container {
    background-color: var(--neutrals-50);
    padding: 32px 32px;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.measure-information-container-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.measure-information-button-copy {
    margin-left: auto;
}

.measure-copy-modal {
    max-width: 800px;
    width: 800px;
}
