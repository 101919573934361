.participant-much-details-card {
    height: auto;
    display: flex;
}

.participant-much-details-card-list {
    display: flex;
    flex-direction: column;
    gap: 46px;
}
.participant-much-details-card-list .list {
    grid-template-columns: 1fr 3fr;
}
