.outerContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(66, 66, 66, 0.5);
    backdrop-filter: blur(4px);
    z-index: 100;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: var(--shadow-lg);
    border-radius: 8px;
    max-width: 532px;
    min-width: 532px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    max-height: 90vh;
    overflow: auto;
}

.container:global(.large) {
    min-height: 500px;
}

.headerContainer {
    color: var(--neutrals-900);
    padding: 24px 24px 8px;
}

.bodyContainer {
    padding: 0 24px 16px 24px;
    color: var(--neutrals-500)
}

.footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12px 24px 24px 12px;
    gap: 12px;
}
.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12px 24px 24px 12px;
    gap: 12px;
}
