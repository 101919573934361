.fadeIn {
    animation: fade_in 5s ease;
}

@keyframes fade_in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeOut {
    animation: fade_out 1s ease;
}

@keyframes fade_out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
