.side-bar-container {
    background-color: var(--neutrals-800);
    height: 100vh;
    min-width: 280px;
    position: fixed;
    z-index: 100;
    transition: 0.4s;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

@media only screen and (min-width: 1900px) {
    .app-navigation-side-menu-button {
        display: none;
    }
    .side-bar-container {
        position: relative;
        left:0 !important;
        z-index: 0;
    }
    .welcome-page {
        display: flex
    }
    .side-bar-head-close {
        display: none;
    }
}
