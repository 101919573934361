.objective-agreement-tag {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.objective-agreement-card-top {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 15px;
}
.objective-agreement-card-top-text {
display: flex;
    flex-direction: column;
    gap: 15px;
 }
.objective-agreement-card-top-comment {
    border-bottom: 1px solid var(--neutrals-200);
}
.objective-agreement-top-tags {
    display: flex;
    gap: 24px;
}
.objective-agreement-tag.full_achieved {
    background-color: var(--success-50);
    color: var(--success-900);
}
.objective-agreement-tag.full_achieved svg rect {
    fill: var(--success-900);
}
.objective-agreement-tag.open {
    background-color: var(--neutrals-200);
    color: var(--neutrals-800);
}
.objective-agreement-tag.open svg rect {
    fill: var(--neutrals-800);
}
.objective-agreement-tag.not_achieved {
    background-color: var(--error-50);
    color: var(--error-900);
}
.objective-agreement-tag.not_achieved svg rect {
    fill: var(--error-900);
}
.objective-agreement-tag.partially_achieved {
    background-color: var(--warning-50);
    color: var(--warning-900);
}
.objective-agreement-tag.partially_achieved svg rect {
    fill: var(--warning-900);
}
.objective-agreement-top-created-at {
    display: flex;
    align-items: center;
    gap: 9px;
    color: var(--neutrals-500);
}
.objective-agreement-top-created-at svg path {
    fill: var(--neutrals-500);
}
.objective-agreement-card-bottom {
    padding-top: 18px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.objective-agreement-card-bottom-comments {
    display: flex;
    gap: 16px;
}
.objective-agreement-card-bottom-comments-text {
color: var(--neutrals-500);
}
