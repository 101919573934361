.container {
    display: flex;
    flex-direction: column;
    gap: 21px;
}
.title {
    display: flex;
    justify-content: space-between;
}

.historiesContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
    align-items: flex-start;
}

.historyContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
}

.historyContainer label {
    font-weight: bold;
}

.historyContainer span {
    padding-left: 3px;
    font-weight: normal;
}