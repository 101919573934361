.company-logo {
    width: 48px;
    height: 48px;
    background-color: var(--neutrals-50);
    border-color: var(--neutrals-100);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.company-logo > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
