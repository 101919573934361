.input {
    outline: none;
    padding: 8px 16px;
    border-radius: 6px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
    border: 1px solid var(--neutrals-300);
    width: 100%;
    box-sizing: border-box;
    height: 40px;
}
.input-textarea {
    outline: none;
    padding: 8px 16px;
    border-radius: 6px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
    border: 1px solid var(--neutrals-300);
    width: 100%;
    box-sizing: border-box;
}
.input-textarea::placeholder {
    color: var(--neutrals-400);
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
}
.input::placeholder {
    color: var(--neutrals-400);
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
}
.input-container {
    position: relative;
}
.input-icon {
    position: absolute;
    left: 8px;
    bottom: 8px;
}
.input.input-icon-left {
    padding-left: 35px;
}
.input-textarea.input-icon-left {
    padding-left: 35px;
}
.input-label-and-field {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.input-enter input {
padding-left: 34px;
}
.input-enter-wrapper {
    position: relative;
    width: 100%;
}
.input-enter-icon {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}
