.measure-user {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.measure-user > .label {
    color: var(--neutrals-400);
}
.measure-user-add-user-button {
    border-radius: 50%;
    border: 1.5px dashed var(--neutrals-300);
    display: flex;
    justify-content: center;
    align-items: center;
}
.measure-user-show-users-only-names {
    display: flex;
    flex-direction: column;
    gap: 13px;
    color: var(--neutrals-500);
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.measure-user-show-users {
    display: flex;
    gap: 10px;
}
.measure-user-avatar {
    position: relative;
    cursor: default;
}
.measure-user-avatar:hover .measure-user-avatar-hover-name {
    display: flex;
}
.measure-user-avatar-hover-name {
    display: none;
    position: absolute;
    bottom: -30px;
    padding: 5px;
    background-color: var(--neutrals-100);
    border-radius: 5px;
    width: fit-content;
    white-space: nowrap;
}
.measure-user-avatar:hover .measure-user-avatar-remove {
    display: block;
}
.measure-user-avatar-remove {
    position: absolute;
    top: -50%;
    transform: translateX(50%);
    display: none;
}
.measure-user-avatar-remove:hover svg {
    cursor: pointer;
}
.measure-user-avatar-remove:hover svg path {
    fill: var(--error-500);
}
.measure-user-name-remove {
    display: none;
    position: absolute;
    left: 0;
    border-radius: 5px;
    transform: translateX(-50%);
}
