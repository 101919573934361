.create-tasks-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 1104px;
}
.create-tasks-form-filter {
    display: flex;
    gap: 24px;
    width: 100%;
}
.create-tasks-form-date {
    display: flex;
    flex-direction: column;
}
.create-task-form-input-elements {
    flex: 1;
    box-sizing: border-box;
}
.create-task-form-input-elements-text {
    flex: 2.075;
    box-sizing: border-box;
}
.task-form-measure-fixed {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
