.side-bar-item-container {
    margin-bottom: 14px;
}
.side-bar-item {
    display: flex;
    color: var(--neutrals-400);
    border-radius: 6px;
    justify-content: space-between;
    cursor: pointer;
}
.side-bar-item:hover {
    background-color: var(--neutrals-700);
}
.side-bar-item-icon-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: relative;
}
.side-bar-item.active {
    background-color: var(--neutrals-700);
    color: #fff;
}
.side-bar-item.active:hover {
    background-color: var(--neutrals-600);
}

.side-bar-item svg path {
    fill: var(--neutrals-400);
}

.side-bar-item.active svg path {
    fill: #fff;
}

.side-bar-item.primary {
    padding: 8px;
}

.side-bar-item.secondary {
    padding: 4px 8px;
}
.side-bar-item-children {
    margin-top: 10px;
}
