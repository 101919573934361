.create-measure-input-title {
    margin-bottom: 40px;
}
.create-process-input-field-underscore-first-letter:first-letter {
    text-decoration: underline;
}
.create-process-input-field-checkbox {
    display: flex;
    gap: 8px;
}
.create-process-input-field-single-checkbox {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: var(--shadow-sm);
    width: 100%;
    display: flex;
    align-items: center;
}
