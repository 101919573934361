.full-screen-side-bar-item {
    display: flex;
    gap: 9px;
    align-items: center;
    padding: 4px 8px 4px 4px;
    border-radius: 6px;
    color: var(--neutrals-500);
    cursor: default;
}
.full-screen-side-bar-item > svg {
    min-width: 20px;
    min-height: 20px;
}
.full-screen-side-bar-item:hover {
    background-color: var(--neutrals-100);
    color: var(--neutrals-800);
}
.full-screen-side-bar-item.selected {
    background-color: var(--primary-50);
    color: var(--primary-600);
}
.full-screen-side-bar-item.finished {
    color: var(--neutrals-400);
}
