.user-list-head-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.user-list-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.user-list-head-search {
    display: flex;
    gap: 16px;
    align-items: center;
}
.user-list-navigation {
    display: flex;
    gap: 24px;
    color: var(--neutrals-500);
    margin-top: 24px;
    position: relative;
    padding: 0 12px;
}
.user-list-navigation-item {
    padding-bottom: 16px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.user-list-navigation-item.active {
    color: var(--primary-600);
    border-bottom: 2px solid var(--primary-600);
}
.user-list-object-list-item-container {
    display: flex;
    justify-content: space-between;
    position: relative;
}
.user-list-object-list-item {
    display: flex;
    gap: 8px;
    align-items: center;
}
.user-list-object-list-item-text-description {

}
.user-list {
    max-height: 100%;
    height: 100%;
    overflow: auto;
}
.user-list-inner {

}

/**
Animation - note: somehow translate does not like the new flex gap and therefore the gap property needs to be added to the y-axis
 */
.user-list-active-enter {
    transform: translate(110%, -16px);
    position: relative;
    top: 0;
}
.user-list-active-enter-active {
    transform: translate(0%, -16px);
    position: relative;
    top: 0;
    transition: all 500ms ease;
}
.user-list-active-exit {
    transform: translate(0%, -16px);
}
.user-list-active-exit-active {
    position: relative;
    top: 0;
    transform: translate(110%, -16px);
    transition: all 500ms ease;
}
