.user-details-measure-table-items {
    display: flex;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid var(--neutrals-200);
}
.user-details-measure-table-date {
color: var(--neutrals-400);
    justify-content: center;
    display: flex;
}
.user-details-measure-table-add {
    margin-top: 24px;
}
.user-details-measure-table {
    padding:0 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.user-details-measure-table-single-item {
    flex: 1;
}
.user-details-measure-table-single-item-button {
    flex: 1;
    justify-content: flex-end;
}
