.add-user-to-measure {
    display: flex;
    gap: 24px;
    align-items: center;
}
.add-user-to-measure-avatar {
    width: 24px;
    height: 24px;
}
.add-user-to-measure-avatar > img {
width: 100%;
    height: 100%;
    object-fit: fill;
}
