.button-container {
    display: flex;
    user-select: none;
    max-height: 48px;
}
.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: default;
    align-self: start;
}
.button.tiny {
    padding: 4px 8px;
}
.button.tiny.icon {
    padding: 4px;
}
.button.small {
    padding: 8px 16px;
}
.button.small.icon {
    padding: 9px;
}
.button.medium {
    padding: 12px 16px 12px 16px;
}
.button.medium.icon {
    padding: 8px;
}
.button.large {
    padding: 16px 20px;
}
/* primary button */
.button.primary {
    border-color: var(--primary-500);
}
/* primary button filled */
.button.primary.filled {
    background: var(--primary-500);
    color: #fff;
}
.button.primary.filled:hover:not(.disabled) {
    background-color: var(--primary-400);
}
.button.primary.filled svg path {
    fill: #fff;
}
.button.primary.filled.disabled {
    background: var(--neutrals-100);
}
/* outline */
.button.outline {
    border-width: 1px;
    border-style: solid;
}
.button.primary.outline {
    color: var(--primary-500);
    border-color: var(--primary-500);
}
.button.primary.outline svg path {
    fill: var(--primary-500)
}
.button.primary.outline:hover:not(.disabled),.button.primary.link:hover:not(.disabled){
background-color: var(--primary-50);
}

.button.primary.link {
    color: var(--primary-500);
}
.button.primary.link svg path {
    fill: var(--primary-500);
}
/* button secondary */
.button.secondary {
    color: var(--neutrals-900);
}
.button.secondary svg path {
    fill: var(--neutrals-900);
}
.button.secondary.filled {
    background-color: var(--neutrals-100);
}
.button.secondary.filled:hover:not(disabled) {
    background-color: var(--neutrals-200);
}
.button.secondary.outline {
    border: 1px solid var(--neutrals-300);
}
.button.secondary.outline:hover:not(.disabled) {
    border-color: var(--neutrals-700);
}
.button.secondary.outline.disabled {
    color: #D1D5DB;
}
.button.secondary.disabled svg path {
    fill: #D1D5DB;
}
.button.secondary.link {
    color: var(--neutrals-900)
}
.button.secondary.link svg path {
    fill: var(--neutrals-500);
}
.button.secondary.link:hover:not(.disabled) {
    background-color: var(--neutrals-100);
}

/*button disabled*/
.button.disabled {
    color: var(--neutrals-300) !important;
    border-color: var(--neutrals-300) !important;
}
.button.disabled svg path {
    fill: var(--neutrals-300) !important;
}
.button.danger {
    border: 1px solid var(--error-500);
    color: var(--error-500);
}
.button.danger svg path {
    fill: var(--error-500);
}
