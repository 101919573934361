
.absence-calendar-container-wrapper {
    overflow-y: auto;
}

.absence-calendar-container {
    max-height: 40rem;
    box-sizing: border-box;
}
.absence-calendar {
display: grid;
    grid-template-columns: 10rem 5rem auto;
    position: relative;
}
.absence-calendar.sticky-header {
    position: sticky;
    top: 0;
    z-index: 2;
}
.absence-calendar-body {
    display: grid;
    grid-template-columns: 10rem 5rem auto;
    position: relative;
    max-height: 100%;
    box-sizing: border-box;
    padding-bottom: 5rem;
}
.absence-calendar-today {
    display: flex;
    justify-content: center;
}
.absence-calendar-days {
    display: flex;
    width: 100%;
    position: relative;
}
.absence-calendar-days-loading {
    box-shadow: var(--shadow-base);
    border-radius: 4px;
    padding: 8px;
    position: relative;
    overflow: hidden;
    min-height: 20px;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: 1rem;
}
.absence-calendar-days-wrapper {
    display: grid;
    flex-direction: column;
    width: 100%
}
.absence-calendar-weekend {
    color: var(--neutrals-400);
    background-color: var(--neutrals-100);
    text-align: center;
    flex: 1;
}
.absence-calendar-week {
    color: var(--neutrals-400);
    text-align: center;
    flex: 1
;
}
.presence-calendar-attendance {
    color: var(--neutrals-400);
    border-bottom: 1px solid var(--neutrals-100);
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
}
.presence-calendar-attendance-weekend {
    color: var(--neutrals-400);
    background-color: var(--neutrals-100);
}
.absence-calendar-presence {
    border-bottom: 1px solid var(--neutrals-100);
}
.presence-calendar-attendance.today {
    padding-right: 34px;
    padding-left: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.absence-calendar-name-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--neutrals-100);
    justify-content: flex-end;
}
.absence-calendar-name-archived {
    font-size: 80%;
}
.absence-calendar-name-full-name {
    display: flex;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.absence-calendar-wrapper {
    display: flex;
}

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}
