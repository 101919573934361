.single-objective-deadline-overdue svg path {
    fill: var(--error-500) !important;
}

.single-objective-deadline-and-text {
    display: flex;
    width: 100%;
    gap: 12px;
}

.single-objective-deadline-and-text-deadline {
    min-width: 75px;
    display: flex;
    justify-content: center;
}

.single-objective-objective-open svg path {
    fill: var(--neutrals-500);
}

.single-objective-objective-not_achieved svg path {
    fill: var(--error-900);
}

.single-objective-objective-partially_achieved svg path {
    fill: var(--warning-900);
}

.single-objective-objective-full_achieved svg path {
    fill: var(--success-900);
}
