.details-form-list-calendar {
    display: flex;
    align-items: center;
}
.details-form-list-calendar input {
    color: var(--neutrals-800);
    outline: none;
    border: 1.5px solid transparent;
    border-radius: 6px;
    cursor: default;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}
.details-form-list-calendar:hover {
    background-color: var(--neutrals-100);
    border-radius: 6px;
}
.details-form-list-calendar:hover input {
    background-color: var(--neutrals-100);
    border-radius: 6px;
}
.details-form-list-calendar.archived:hover {
    background-color:#fff;
}
.details-form-list-calendar.archived:hover input {
    background-color:#fff;
}


