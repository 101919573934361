.datepicker-container {
    position: relative;
}
.datepicker-custom-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
}
.datepicker-position {
    z-index: 100;
}
.datepicker-all-days {
    padding: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
}
.datepicker-day {
    color: var(--neutrals-400);
}
.datepicker-day-current-month {
    color: var(--neutrals-800);
}
.datepicker-calender {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: none;
    box-shadow: var(--shadow-lg);
    padding: 8px;
}
.datepicker-all-days:hover:not(.react-datepicker__day--excluded) {
    color: var(--primary-500);
    background-color: var(--neutrals-100);
    border-radius: 8px;
}
.datepicker-all-days.react-datepicker__day--selected {
    color: #fff;
    background-color: var(--primary-500);
    border-radius: 8px;
}
.datepicker-calender .react-datepicker__header {
    background-color: #fff;
    color: var(--neutrals-900);
    border-bottom: none;
}
.react-datepicker__day-name {
    color: var(--neutrals-900);
    padding: 8px;
}
.react-datepicker__day {
    margin: 0;
}
.react-datepicker__triangle {
    display: none;
}
.datepicker-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
}
.react-datepicker__month {
    margin: 0;
}
.react-datepicker__week {
    display: flex;
    justify-content: space-between;
}
.react-datepicker__day--keyboard-selected {
    background-color: transparent;
}
.react-datepicker__day--excluded {
    opacity: 0.3;
}
