.loading-view {
    background-color: white;
    min-width: 100vw;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    opacity: 0.5;
}
