.qm-document-template-form {
background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.qm-document-template-form-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(66, 66, 66, 0.5);
    backdrop-filter: blur(4px);
}
.qm-document-template-form-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.qm-document-template-form-input {
    margin-bottom: 1rem;
}
.qm-document-template-form-button div {
    width: 100%;}
.qm-document-template-form-upload {
    margin-bottom: 1rem;
}
