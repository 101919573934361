.login-page-container {
    background-color: var(--neutrals-50);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.login-page > h3 {
    text-align: left;
}
.login-page {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    padding: 0 32px 58px
}
.login-page > img {
    align-self: flex-end;
}
.login-page-container-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}
.login-page-container-label {
 line-height: 14px;
    color: var(--neutrals-900);
    margin-bottom: 10px;
    display: block;
    font-weight: 500;
}
.login-page-container-input {
    border: 1px solid #D4D4D8;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 8px 16px;
}
.login-page-container-input:focus-visible {
    border: 1.5px solid var(--primary-500);
outline: none;
    box-sizing: border-box;

}
.login-page-container-input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}
.login-page-width {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.ez-on-rails-form-outer-container form {
    width: 100%;
}
.login-page-container-button-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 2em;
}
.login-page-button-login {
    background: #F97316;
    border-radius: 6px;
    color: #fff;
    padding: 12px 16px;
    border: none;
}
.login-page-container-password-reset-link.btn.btn-outline-primary {
    /* Labels/L4 */

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height, or 100% */


    /* Primary/500 */

    background-color: transparent;
    border: none;
    text-decoration: underline;
}
.login-page-container-password-reset-link a {
    color: var(--primary-500);
}
.login-page-button-register {
    display: none;
}
.ez-on-rails-form-links-container {
    position: absolute;
    right: 0;
    top: 35%;
}
.ez-on-rails-form-outer-container {
    position: relative;
}
.login-page-error-text {
    position: absolute;
    bottom: -20px;
}
.login-page-container-input {
    position: relative;
}
.login-page-container-input-wrapper {
    position: relative;
    margin-bottom: 30px;
}
