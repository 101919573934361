.accordion-elements-container {
    overflow: hidden;
    transition: height 0.3s;
}
.accordion-elements {
    padding: 8px 16px;
}
.accordion-title-number-chevron {
    display: flex;
    gap: 19px;
    align-items: center;
}
.accordion-title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    background-color: var(--neutrals-100);
    border-radius: 8px;
}
.accordion-title.active {
    background-color: var(--primary-50);
    color: var(--primary-600);
}
.accordion-title:hover {
    background-color: var(--neutrals-200);
}
.accordion-title.active:hover {
    background-color: var(--primary-100);
}
.accordion-container {
    box-sizing: border-box;
}
