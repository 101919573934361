.user-measure-attendance-management {
    background-color: var(--neutrals-50);
    padding: 32px 32px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    grid-gap: 26px;
    gap: 26px;
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
}
.user-measure-attendance-management-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
}
.user-measure-attendance-management-card {
    overflow: scroll;
}
.user-measure-attendance-management-dropdown-body {
}
.user-measure-attendance-management-dropdown {
}
.user-measure-attendance-management-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
