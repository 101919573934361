.participant-archive-form-element {
    display: flex;
    gap: 24px;
}
.participant-archive-form-element > div {
    width: 100%;
}
.participant-archive-form-element-line {
    border-bottom: 1px solid var(--neutrals-200);
}
