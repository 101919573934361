:root {
    /* Brand Color */
    --primary-900: #7C2D12;
    --primary-800: #9A3412;
    --primary-700: #C2410C;
    --primary-600: #EA580C;
    --primary-500: #F97316;
    --primary-400: #FB923C;
    --primary-300: #FDBA74;
    --primary-200: #FED7AA;
    --primary-100: #FFEDD5;
    --primary-50: #FFF7ED;

    /* Black and white color */
    --neutrals-900: #18181B;
    --neutrals-800: #27272A;
    --neutrals-700: #3F3F46;
    --neutrals-600: #52525B;
    --neutrals-500: #71717A;
    --neutrals-400: #A1A1AA;
    --neutrals-300: #D4D4D8;
    --neutrals-200: #E4E4E7;
    --neutrals-100: #F4F4F5;
    --neutrals-50: #FAFAFA;

    /* Red/error Color */
    --error-900: #7F1D1D;
    --error-500: #EF4444;
    --error-100: #FEE2E2;
    --error-50: #FEF2F2;

    /* Green/success Color */
    --success-900: #064E3B;
    --success-500: #10B981;
    --success-100: #D1FAE5;
    --success-50: #ECFDF5;

    /* Brown/yellow/warning Color */
    --warning-900: #713F12;
    --warning-500: #EAB308;
    --warning-100: #FEF9C3;
    --warning-50: #FEFCE8;
}
