.participant-details {
    display: flex;
    flex-direction: row;
}
.participant-details-container {
    display: flex;
    flex-wrap: wrap;
}
.participant-details-form {
}
.participant-details-picture {
    margin-left: auto;
}
.participant-details-card-avatar {
   margin-left: auto;
 }
