.container {
    cursor: default;
    position: relative;
}
.container:global(.disabled) {
    color: grey;
}

.titleContainer {
    position: relative;
    padding: 6px 8px;
    border-radius: 6px;
}
.container:global(.disabled) > .titleContainer {
    color: var(--neutrals-100);
    cursor: pointer;
}


.itemsContainer {
    position: absolute;
    z-index: 3;
    padding: 4px 8px;
    box-shadow: var(--shadow-lg);
    border-radius: 6px;
    border: 1px solid var(--neutrals-200);
    background-color: #fff;
    max-height: 300px;
    overflow: auto;
}
.itemsContainer:global(.align-right) {
    right: 0;
}

/* one item */
.itemsContainer > div {
    padding: 8px;
    border-radius: 6px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}
.itemsContainer > div:hover {
    background-color: var(--neutrals-100);
}

.noItemsText {
    padding: 8px;
    border-radius: 6px;
    position: relative;
    width: 100%;
}
.noItemsText:hover {
    background-color: var(--neutrals-100);
}
