.update-need-for-action-deadline {
    padding: 32px;
    overflow: auto;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 24px;
    position: relative;
}
.update-need-for-action-deadline-icon {
    position: absolute;
    right: 8px;
    top: 8px;
}
.update-need-for-action-comment-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
    overflow: auto;
    position: relative;
}
.update-need-for-action-form-progress {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}
