.print-qm-document-modal-body-documents {
    display: flex;
    gap: 24px;
}
.print-qm-document-modal-body-body {
    padding: 15px 0 15px;
}
.print-qm-document-modal-documents {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 24px;
}
