.single-comment {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
}
.single-comment-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.single-comment-wrapper .create-measure-detail-enter-hint {
    justify-self: flex-end;
    margin: 0;
}
