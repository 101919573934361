h1 {
    font-family: Inter;
    font-size: 74px;
    font-style: normal;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: -0.02em;
    text-align: center;

}
h2 {
    font-family: Inter;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: center;

}
h3 {
    font-family: Inter;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: -0.0175em;
    text-align: center;

}
h4 {
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.016em;
    text-align: center;

}
h5 {
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.015em;
    text-align: center;
    display: flex;
    margin: 0;
}
h6 {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.0125em;
    text-align: center;
    margin: 0;
}
.p1-regular {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
}
.p1-medium {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
}
.p1-semi-bold {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
}
.p1-bold {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
}
.p2-regular {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.015em;
    text-align: left;
}
.p2-medium {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: left;
}
.p2-semi-bold {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: left;
}
.p2-bold {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: left;
}
.p3-regular {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
}
.p3-medium {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.005em;
    text-align: left;
}
.p3-semi-bold {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.005em;
    text-align: left;
}
.p3-bold {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.005em;
    text-align: left;
}
.p4-regular {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}
.p4-medium {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}
.p4-semi-bold {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}
.p4-bold {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}
.p5-regular {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}
.p5-medium {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}
.p5-semi-bold {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}
.p5-bold {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}
body {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}
