.participant-need-for-action-modal-container {
    z-index: 100;
}

.participant-need-for-action-modal {
    display: flex;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    padding: 32px 32px 0 32px;

}

.participant-need-for-action-modal-right-side {
    padding-left: 56px;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 32px;
    justify-content: flex-start;
    box-sizing: border-box;
}
.participant-need-for-action-modal-body {
    display: flex;
    height: 100%;
}

.participant-need-for-action-modal-left-side {
    border-right: 1px solid var(--neutrals-200);
    height: 100%;
}

.participant-need-for-action-modal-footer {
    border-top: 1px solid var(--neutrals-200);
    padding: 25px 32px;
    background-color: #fff;
}
