.export-presence-modal-body {
    display: flex;
    gap: 24px;
}

.export-presence-modal-body-dates {
    display: flex;
    gap: 10px;
    width: 100%;
}
.export-presence-modal-body-dates > div {
    flex: 1;
}
