.form-input {
    display: flex;
    flex-direction: column;
}
.form-input-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 10px;
}
.form-input input {
    border: 1px solid var(--neutrals-300);
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}
.form-input input:hover {
    border-color: var(--neutrals-400);
}
.form-input input:focus-visible {
    border: 1px solid var(--primary-500);
    outline: none;
}
.form-input input.value:not(:focus) {
    border-color: var(--neutrals-300);
}
.form-input input.small {
    height: 32px;
    padding: 8px;
}
.form-input input.medium {
    height: 40px;
    padding: 8px 16px;
}
.form-input input.large {
    height: 48px;
    padding: 8px 16px;
}
.form-input a {
    color: var(--primary-500);
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
}
.form-input .input-helper-error-container {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding-top: 8px;
}
.form-input .helper {
    color: var(--neutrals-500);
}
.form-input .error {
    border-color: var(--error-500);
    color: var(--error-500);
}
.error {
    border-color: var(--error-500) !important;
    color: var(--error-500) !important;
}
.form-input-field {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}
.form-input-field input {
    width: 100%
}
.form-input-field.form-input-field-icon-right input{
    padding-right: 24px;
}
.form-input-field-icon-right-icon {
    position: absolute;
    right: 16px;
}
.form-input-field.form-input-field-icon-left input {
    padding-left: 52px;
}
.form-input-field-icon-left-icon {
    position: absolute;
    left: 16px;
}
