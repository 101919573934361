.course-details-head {
    display: flex;
    justify-content: space-between;
}
.course-details-box {
    display: flex;
    gap: 56px;
}
.course-details-box > div {
    flex: 0.5;
}
.course-details-box > div:last-child {
    flex: 1;
}
.course-details-box .measure-user {
    max-width: 300px;
    white-space: normal;
}
