
.measure-copy {
    /*TODO*/
}

.measure-copy-participant-selection {
    margin-top: 30px;
    border-top: 1px solid;
    padding-top: 20px;
}

.measure-copy-submit {
    font-size: 20px;
    margin-top: 30px;
}
