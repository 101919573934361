.attendance {
    width: 20px;
    min-height: 20px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80%;
    box-sizing: border-box;
    cursor: pointer;
}
.attendance:hover {
    cursor: pointer;
    border-width: 2px;
}

.attendance-null {
    border-color: var(--neutrals-300);
}

.attendance-present {
    border-color: var(--success-500);
    background-color: var(--success-100);
    color: var(--success-900);
}

.attendance-delayed {
    border-color: var(--warning-500);
    background-color: var(--warning-100);
    color: var(--warning-900)
}

.attendance-practicum {
    border-color: var(--success-500);
    background-color: var(--success-100);
    color: var(--success-900);
}

.attendance-interview {
    border-color: var(--success-500);
    background-color: var(--success-100);
    color: var(--success-900);
}

.attendance-absent_unexplained {
    border-color: var(--error-500);
    background-color: var(--error-100);
    color: var(--error-900);
}

.attendance-absent_explained {
    border-color: var(--warning-500);
    background-color: var(--warning-100);
    color: var(--warning-900)
}

.attendance-other {
    border-color: var(--warning-500);
    background-color: var(--warning-100);
    color: var(--warning-900)
}

.attendance-sickness {
    border-color: var(--warning-500);
    background-color: var(--warning-100);
    color: var(--warning-900)
}

.attendance-instruction_free_time {
    border-color: var(--neutrals-500);
    background-color: var(--neutrals-200);
    color: var(--neutrals-900);
}

.attendance-measure_end {
    border-color: #0EA5E9;
    background-color: #E0F2FE;
    color: #0C4A6E;
}

.attendance-phone {
    border-color: var(--success-500);
    background-color: var(--success-100);
    color: var(--success-900);
}

.attendance-video_phone {
    border-color: var(--success-500);
    background-color: var(--success-100);
    color: var(--success-900);
}

.attendance-label {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    text-align: center;
    letter-spacing: 0.025em;
    color: var(--neutrals-400);
}

.attendance-container {
    display: flex;
    flex-direction: column;
    gap: 9px;
    padding: 8px 2px;
}

