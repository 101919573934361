.side-bar-item-nav {
    display: flex;
    color: var(--neutrals-500);
    cursor: pointer;
    padding-left: 20px;
}
.side-bar-item-nav:hover {
    color: var(--neutrals-300);
}
.side-bar-item-nav.active {
    color: #fff;
}
.side-bar-item-nav-spacer {
    width: 20px;
    border-left: 1.5px solid var(--neutrals-600);
}
.side-bar-item-nav.active .side-bar-item-nav-spacer {
    border-color: #fff;
}
.side-bar-item-nav-text {
    padding-top: 6px;
    padding-bottom: 6px;
}
