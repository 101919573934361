.support-plan-details-title {
    display: flex;
    justify-content: space-between;
}
.support-plan-details-objective-agreements {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 26px 32px;
}
.delete-red svg path{
    fill: var(--error-900);
}
.support-plan-details-calendar > .datepicker-container {
    width: fit-content;
}
