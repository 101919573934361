.user-details-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.user-details-general {
    padding: 32px;
    background-color: #fff;
    box-shadow: var(--shadow-base);
    border-radius: 8px;
}
.user-details-general-title {
    color: var(--neutrals-400);
    margin-bottom: 22px;
}
.user-details-general-information {
    display: flex;
}
.user-details-general-information-container-image {
display: flex;
justify-content: flex-end}
.user-details-general-picture {
    width: 218px;
    height: 260px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}
.user-details-general-picture:hover .user-details-general-picture-upload {
    opacity: 0.5;
}
.user-details-general-picture-upload {
    display: flex;
    position: absolute;
    bottom: 0;
    justify-content: center;
    background-color: white;
    opacity: 0;
    width: 100%;
    transition: opacity 0.5s;
}
.user-details-general-picture:hover .user-details-general-picture-remove {
    opacity: 0.8;
}
.user-details-general-picture-remove {
    display: flex;
    position: absolute;
    top: 0;
    justify-content: center;
    background-color: white;
    opacity: 0;
    width: 100%;
    transition: opacity 0.5s;
}
.user-details-general-picture-remove svg path {
    fill: var(--error-500)
}
.user-details-general-picture > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.user-details-general-information-container {
    flex: 1;
}
.user-details-general-information-container-text {
    flex: 1;
}
.user-details-measures {
    background-color: #fff;
    box-shadow: var(--shadow-base);
    border-radius: 8px;
}
.user-details-measures > .p2-medium {
    padding: 24px;
}
.user-details-navigation {
    padding: 24px;
    display: flex;
    gap: 24px;
    color: var(--neutrals-500);
    border-bottom: 1px solid var(--neutrals-200);
}
.user-details-navigation-item {
    padding-bottom: 16px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.user-details-navigation-item.active {
    color: var(--primary-600);
    border-bottom: 2px solid var(--primary-600);
}
.user-details-box {
    display: flex;
    flex-wrap: wrap;
}
.user-details-box-details {
    flex: 1;
}
