.update-task-form-filter {
    display: flex;
    gap: 24px;
    width: 100%;
    align-items: center;
}
.update-task-form-date {
    display: flex;
    flex-direction: column;
}
.update-task-form-text {
    width: 100%;
}
.update-task-details-render-comments {
    padding: 32px;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}
.update-task-details-render-comments-border {
    border-left: 1px solid var(--neutrals-900);
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-left: 20px;
}
.update-task-details-render-comments-scroll {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: auto;
    position: relative;
}
.update-task-details-comments {
    display: flex;
    gap: 12px;
    box-sizing: border-box;
}
.update-task-details-comments-loading {
    height: 12px;
}
.update-task-details-comments-input {
    display: flex;
    gap: 12px;
    box-sizing: border-box;
    align-items: center;
    color: var(--neutrals-400);
}
.update-task-details-comments-input svg path {
    fill: var(--neutrals-400);
}
.update-task-details-date-input {
    display: flex;
    gap: 9px;
    align-items: center;
}
.update-task-details-date {
    display: flex;
    gap: 9px;
}
.update-task-details-date > span {
    padding-top: 4px;
}
.update-task-details-single-comment {
    padding-top: 4px;
}
.update-task-details-single-comment-from {
    color: var(--neutrals-400);
}

