.select-wrapper {
    position: relative;
}
.select-container {
    display: flex;
    gap: 15px;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.select-dropdown {
    position: absolute;
    padding: 8px;
    box-shadow: var(--shadow-lg);
    border-radius: 6px;
    border: 1px solid var(--neutrals-200);
    width: fit-content;
    background-color: #fff;
    z-index: 1;
    max-height: 200px;
    overflow: auto;
}
.select-dropdown-option {
    cursor: pointer;
    border-radius: 6px;
    padding: 8px;
}
.select-dropdown-option:hover {
    background-color: var(--neutrals-100);
}
.select-dropdown-option.selected {
    background-color: var(--primary-500);
    color: #fff;
}
