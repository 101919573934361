
.activity-indicator {
    border: 4px solid var(--neutrals-100);
    border-top: 4px solid var(--warning-500);
    border-bottom: 4px solid var(--warning-500);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: activity-indicator-anim 2s linear infinite;
}

@keyframes activity-indicator-anim {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
