.create-task-container {
    padding: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
}

.create-tasks {
    background-color: #fff;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    overflow: hidden;
}

.create-tasks-border {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 32px 32px 24px;
}

.create-tasks-title {
    display: flex;
    justify-content: space-between;
}

.create-tasks-delete {
    cursor: pointer;
}

.create-tasks-delete:hover svg path {
    fill: var(--error-500);
}

.create-tasks-border h5 {
    margin-bottom: 40px;
}

.create-tasks-border > div {
    border-bottom: 1px solid var(--neutrals-200);
    padding-bottom: 24px;
}

.create-tasks-footer {
    border-top: 1px solid var(--neutrals-200);
    padding: 25px 32px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

.create-tasks-foreground {
    z-index: 100;
}

.create-tasks-form-text {
    padding: 32px;
    overflow: auto;
    height: 100%;
}
