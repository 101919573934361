.participant-archived-information-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%
}
.hr-line {
    border: 1px solid #E4E4E7;
    width: 100%;
}
.participant-archived-information-form-textarea textarea {
    resize: vertical;
}
