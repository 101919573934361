.welcome-page {
    width: 100%;
    box-sizing: border-box;
}
.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
}
.app-container-routes {
    height: 100vh;
    overflow: auto;
}
