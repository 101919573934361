.headContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.headContainer > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headButtonsContainer {
    display: flex;
    gap: 16px;
    align-items: center;
}

.bodyContainer {
    max-height: 100%;
    height: 100%;
    overflow: auto;
}

.nameClosed {
    color: lightgray;
}

.itemContainer {
    display: flex;
    justify-content: space-between;
}

.itemContainer > div {
    display: flex;
    gap: 8px;
    align-items: center;
}

.itemDescription {
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}