.user-tasks {
    background-color: var(--neutrals-50);
    padding: 38px 32px;
    flex: 1 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
    height: 100%;
    box-sizing: border-box;
}
.user-tasks-head {
    display: flex;
    justify-content: space-between;
}
.user-tasks-measures {
    display: flex;
    gap: 24px;
}
.user-tasks-measure {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    padding: 5px 16px;
    border: 1px solid var(--neutrals-400);
    border-radius: 8px;
    cursor: pointer;
}
.user-tasks-measure.active {
    background-color: var(--primary-500);
    color: #fff;
    border-color: var(--primary-500);
}
.user-tasks-dropdown-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user-tasks-filter-dropdown {
    display: flex;
    gap: 35px;
    justify-content: flex-end;
}
.user-tasks-dropdown-user {
    border-bottom: 1px solid var(--neutrals-200);
    position: absolute;
    width: 100%;
    bottom: -2.5px;
    left: 0;
}
.user-tasks-dropdown-first-user {
    margin-bottom: 5px;
}
.user-tasks-dropdown-second-user {
    margin-top: 5px;
}
/* workaround since I don't know how to solve it otherwise */
.user-tasks-dropdown-first-user > .user-tasks-dropdown-first-user {
    margin: 0;
}
.user-tasks-dropdown-second-user > .user-tasks-dropdown-second-user {
    margin: 0;
}
