.participant-notes-outer-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}
.participant-notes-outer-container.open {
    background: rgba(66, 66, 66, 0.5);
    backdrop-filter: blur(4px);
    z-index: 100;
}


.participant-notes-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 75%;
    transition: all 1s;
    position: absolute;
    top: 0;
    right: -75%;
    box-sizing: border-box;
    height: 100%;
    z-index: 1;
    padding: 0;
    left: auto;
    background-color: #fff;
}
.participant-notes-container.open {
    right: 0;
}
.participant-notes-container > .body {
    flex-grow: 1;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    overflow: hidden;
}

.participant-notes-container > .footer {
    border-top: 1px solid var(--neutrals-200);
    padding: 25px 32px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}


@media screen and (max-width: 990px) {
    .participant-notes-container {
        width: 100%;
        right: -100%;
    }
}

.participant-notes-info {
    display: flex;
    justify-content: space-between;
    color: var(--neutrals-400);
    font-size: 90%;
}

