.app-navigation {
    padding: 20px 16px;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid var(--neutrals-200);
}
.app-navigation-main {
    display: flex;
    gap: 20px;
    align-items: center;
}
.app-navigation-sub-menu {
    padding-bottom: 0;
}
.app-navigation-sub {
    width: 100%;
    box-sizing: border-box;
}
.site-title {
    text-align: left;
}
.sub-menu {
    display: flex;
    flex-direction: column;
    gap: 22px;
}
.sub-menu-head {
    display: flex;
    align-items: center;
    gap: 16px;
}
.sub-menu-items {
    display: flex;
    gap: 24px;
    align-items: baseline;
}
.sub-menu-items-dropdown {
    margin-left: auto;
    display: flex;
    gap: 8px;
}
.sub-menu-items-option{
    color: var(--neutrals-500);
    cursor: pointer;
    height: 100%;
    padding-bottom: 18px;
}
.sub-menu-items-option:hover {
    color: var(--neutrals-800)
}
.sub-menu-items-option-active {
    color: var(--primary-500) !important;
    border-bottom: 1px solid var(--primary-500);
}
