.full-screen-side-bar {
background-color: #fff;
    padding: 24px;
    height: 100%;
    box-sizing: border-box;

}
.full-screen-side-bar-back-button {
    margin-bottom: 38px;
    color: var(--neutrals-500) !important;
}
.full-screen-side-bar-back-button svg path {
    fill: var(--neutrals-500) !important;
}
.full-screen-side-bar-items {
display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding-bottom: 10rem;
}
.full-screen-side-bar-title {
    margin-bottom: 24px;
}
