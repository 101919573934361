.user-tasks-card {
    box-shadow: var(--shadow-base);
    border-radius: 8px;
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;
padding: 32px;
    box-sizing: border-box;
    overflow: hidden;
}
.user-tasks-single-task {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    box-shadow: var(--shadow-base);
    border-radius: 4px;
    padding: 8px;
    position: relative;
    cursor: default;
}
.user-tasks-single-task:hover {
    background-color: var(--neutrals-50);
}
.user-tasks-single-task-loading {
    box-shadow: var(--shadow-base);
    border-radius: 4px;
    padding: 8px;
    position: relative;
    overflow: hidden;
    min-height: 20px;
}
.user-tasks-single-task-deadline {
    display: flex;
    gap: 8px;
    align-items: center;
}
.user-tasks-open {
    cursor: pointer;
}
.user-tasks-open:hover svg path {
    fill: var(--success-500);
}
.user-tasks-done {
    cursor: pointer;
}
.user-tasks-done svg path {
    fill: var(--success-500);
}
.user-tasks-single-task-text {
    display: flex;
    align-items: center;
}
.user-tasks-single-task-names {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}
.user-tasks-card-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 8px;
    box-sizing: border-box;
}
.task-overdue {
    color: var(--error-500)
}
