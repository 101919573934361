.side-bar-head-container {
    padding: 32px 16px 24px 16px;
    border-bottom: 1px solid var(--neutrals-700);
    display: flex;
    flex-direction: column;
    gap: 28px;

}
.side-bar-head-logo-and-close {
    display: flex;
    justify-content: space-between;
}
.side-bar-head-close svg {
    background-color: var(--neutrals-700);
    border-radius: 4px;
}
.side-bar-head-close svg path {
    fill: #fff;
}
