.standard-view {
    display: flex;
    height: 100%;
    width: 100%;
}
.standard-view-left {
    height: 100%;
}
.standard-view-right {
    background-color: var(--neutrals-50);
    padding: 32px 32px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    grid-gap: 26px;
    gap: 26px;
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
}
