.lost-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.lost-password-container input {
    outline: none;
    padding: 8px 16px;
    border-radius: 6px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
    border: 1px solid var(--neutrals-300);
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    margin-top: 5px;
}
.reset-password-page-container-button {
    background: #F97316;
    border-radius: 6px;
    color: #fff;
    padding: 12px 16px;
    border: none;
    margin-bottom: 8px;
    margin-top: 8px;
}
