.measure-details {
    background-color: #fff;
    box-shadow: var(--shadow-base);
    border-radius: 8px;
}
.measure-details-nav {
    padding: 24px 32px 0;
    border-bottom: 1px solid var(--neutrals-200);
}
.measure-details-form {
    display: flex;
    padding: 43px 32px;
    flex-direction: column;
    gap: 34px;
}
.measure-details-form-flex {
    display: flex;
    justify-content: space-between;
}
.measure-details-form-information {
    border-bottom: 1px solid var(--neutrals-200);
    flex: 1;
    gap: 22px;
    display: flex;
    flex-direction: column;
}
.measure-details-form-body {
    display: flex;
    flex-wrap: wrap;
    gap: 56px;
}
.measure-details-qm-documents {
    padding: 24px 24px;
    border: 1px solid var(--neutrals-200);
    box-shadow: var(--shadow-sm);
    border-radius: 8px;
    flex: 0.7;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.measure-details-qm-documents-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.measure-details-form-details-user {
    flex: 1;
}
