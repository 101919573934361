.objective-agreement-status-change {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    position: absolute;
    gap: 8px;
    top: 30px;
    border: 1px solid #E4E4E7;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    z-index: 10;
}
.objective-agreement-status-change > div {
    color: #27272A;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    cursor: default;
    padding: 8px;
    box-sizing: border-box;
    white-space: nowrap;
}
.objective-agreement-status-change > div:hover {
    background: #F4F4F5;
    border-radius: 6px;
}
