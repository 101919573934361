.letters {
    flex: 1;
    padding: 1rem;
    border: 1px solid black;
}
.letters-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.letters-nav-new-template {
    justify-self: flex-end;
}
