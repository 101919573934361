.editable-item {
    color: var(--neutrals-800);
    outline: none;
    border: 1.5px solid transparent;
    padding: 0 8px 0;
    border-radius: 6px;
    cursor: default;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    background-color: transparent;

}
.editable-item-archived {
    color: var(--neutrals-800);
    outline: none;
    border: 1.5px solid transparent;
    padding: 0 8px 0;
    border-radius: 6px;
    cursor: default;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}
.editable-item:focus {
    border: 1.5px solid var(--primary-500);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}
.editable-item:hover {
    background-color: var(--neutrals-100);
}
.editable-item:focus:hover {
    background-color: #fff;
}
.editable-readonly-item {
    color: var(--neutrals-800);
    outline: none;
    border: 1.5px solid transparent;
    padding: 0 8px 0;
    border-radius: 6px;
    cursor: default;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;

}
