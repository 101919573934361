.container {
    margin-bottom: 8px;
    cursor: pointer;
}
.container > div {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.container .checkbox {
    min-width: 20px;
    min-height: 20px;
    border: 1px solid var(--neutrals-300);
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
}

.container .checkbox:global(.disabled) {
    cursor: default;
}
.container .checkbox:not(:global(.disabled)):hover {
    border-color: var(--neutrals-400);
}
.container .checkbox:global(.checked) {
    background-color: var(--primary-600);
    border-color: var(--primary-600);
}
.container .checkbox:global(.error) {
    border-color: var(--error-500);
}
