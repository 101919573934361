.participants-list-head-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.participants-list-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.participants-list-head-search {
    display: flex;
    gap: 16px;
    align-items: center;
}
.participants-list-navigation {
    display: flex;
    gap: 24px;
    color: var(--neutrals-500);
    margin-top: 24px;
    padding: 0 12px;
}
.participants-list-navigation-item {
    padding-bottom: 16px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.participants-list-navigation-item.active {
    color: var(--primary-600);
    border-bottom: 2px solid var(--primary-600);
}
.participants-list {
    max-height: 100%;
    height: 100%;
    overflow: auto;
}
.participants-list-inner {

}

.participants-list-need-community-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.2em;
    padding-top: 1.5em;
}
.participants-list-need-community-label {
    color: var(--neutrals-500);
}
.participants-list > .participants-list-need-community-label:first-child {
    padding-top: 0;
}

html {
    height: 100%;
    overflow-y: auto;
    max-width: 100%;
    overflow-x: hidden;
}

/**
Animation - note: somehow translate does not like the new flex gap and therefore the gap property needs to be added to the y-axis
 */
.participants-list-active-enter {
    transform: translate(110%, -16px);
    position: relative;
    top: 0;
}
.participants-list-active-enter-active {
    transform: translate(0%, -16px);
    position: relative;
    top: 0;
    transition: all 500ms ease;
}
.participants-list-active-exit {
    transform: translate(0%, -16px);
}
.participants-list-active-exit-active {
    position: relative;
    top: 0;
    transform: translate(110%, -16px);
    transition: all 500ms ease;
}
