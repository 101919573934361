.outerContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(66, 66, 66, 0.5);
    backdrop-filter: blur(4px);
    z-index: 100;
}
.container {
    padding: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    z-index: 1;
}
.container > div {
    background-color: #fff;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    overflow: hidden;
}

.bodyContainer {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding: 32px 32px 0 32px;
    overflow: hidden;
    flex-grow: 1;
}

.footerContainer {
    border-top: 1px solid var(--neutrals-200);
    padding: 25px 32px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}

