.container {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.headContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.bodyContainer {
    display: flex;
    flex-direction: column;
}

.bodyContainer > div {
    padding: 0.5em;
    border: 1px solid var(--neutrals-400);
    margin-bottom: -1px;
    border-left: none;
    border-right: none;
}