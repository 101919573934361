.form-radiobutton {
    margin-bottom: 8px;
    cursor: pointer;
}
.form-radiobutton > div {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.form-radiobutton .browser-radiobutton {
    display: none;
}
.form-radiobutton .radiobutton {
    width: 20px;
    height: 20px;
    border: 1px solid var(--neutrals-300);
    border-radius: 14px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
}
.form-radiobutton .radiobutton .radiobutton-inner {
    width: 8px;
    height: 8px;
    border-radius: 14px;
    background-color: #fff;
    z-index: 1;
}
.form-radiobutton .radiobutton-error {
    border-color: var(--error-500);
}
.form-radiobutton .radiobutton:hover {
    border-color: var(--neutrals-400);
}
.form-radiobutton .radiobutton.checked {
    background-color: var(--primary-600);
    border-color: var(--primary-600);
}
