.label-1 {
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 11px;
    letter-spacing: 0.025em;
    text-align: left;
}
.label-2 {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.025em;
    text-align: left;
}
.label-3 {
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0.005em;
    text-align: left;
}
.label-4 {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.005em;
    text-align: left;
}
